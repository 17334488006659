import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Determined and strong`}<br parentName="p"></br>{`
`}{`She took a step for the last time.`}</p>
    <p>{`Hiking all alone,`}<br parentName="p"></br>{`
`}{`In the dark with no one but the trees to talk to`}<br parentName="p"></br>{`
`}{`She continued her journey.`}<br parentName="p"></br>{`
`}{`She went higher and higher up the mountain.`}</p>
    <p>{`Her passion made her blind`}<br parentName="p"></br>{`
`}{`Anger overcame her.`}<br parentName="p"></br>{`
`}{`She was reckless`}<br parentName="p"></br>{`
`}{`She didn't see the danger.`}</p>
    <p>{`The ground below betrayed her.`}<br parentName="p"></br>{`
`}{`Her anger dissipated.`}<br parentName="p"></br>{`
`}{`Replaced instead by fear.`}<br parentName="p"></br>{`
`}{`She plummeted down the mountainside.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      